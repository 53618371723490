<style lang="scss" scoped>
/deep/ .comp-exchange-complete {
    max-width: 1200px;
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
    .serial-scan-btn {
        position: absolute;
        z-index: 1;
        right: 11px;
        background: #f5f7fa;
        padding: 4px 10px;
    }
}
.assets {
    font-size: 13px;
    border-bottom: 1px dashed #eee;
    padding: 5px 0;
    .fl {
        max-width: 96%;
    }
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
    .asset-title {
        vertical-align: middle;
        line-height: 1.5;
        white-space: normal;
        display: inline-block;
    }
}
</style>

<template>
    <div>
        <el-dialog title="完成更换" custom-class="c-el-dialog comp-exchange-complete" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="130px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="仓库" prop="warehouseId">
                            <el-input class="c-pw100" :value="dialogData.warehouse && dialogData.warehouse.name" disabled size="medium"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <el-form-item label="使用者" prop="warehouseId">
                            <el-input class="c-pw100" :value="dialogData.assetUser && dialogData.assetUser.name" disabled size="medium"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="更换配件" prop="returnItems">
                            <el-autocomplete :ref="refAutoComplete" class="c-pw100 mrgb10" v-model="keyword" :trigger-on-focus="false" clearable :fetch-suggestions="querySearchAsync" 
                                placeholder="搜索名称/条形码，添加更换配件" @select="handleSelect" @keyup.enter.native="execSelect()">
                                <template slot-scope="{ item }">
                                    <dl class="assets">
                                        <dd class="fl">
                                            <el-image class="asset-image" :src="uploadBaseUrl + item.imageUrl">
                                                <div slot="error">
                                                    <i class="el-icon-picture-outline error-image"></i>
                                                </div>
                                            </el-image>
                                            <span class="asset-title">
                                                <span>{{ mxTranceAssetData(item) }}</span>
                                                <span :class="mxTranceAssetData(item) ? '' : 'mrgl10'">{{ item.value }}</span>
                                                <span class="mrgl5 col_danger" v-if="item.maxCount >= 0 && !$root.isPc">【库存：{{ item.maxCount }}】</span>
                                            </span>
                                        </dd>
                                        <dd class="fr col_danger" v-if="item.maxCount >= 0 && $root.isPc">【库存：{{ item.maxCount }}】</dd>
                                        <div class="clearb"></div>
                                    </dl>
                                </template>
                                <template slot="append" v-if="!$root.isPc"><span @click="openBarcodeHtmlScan()"><i class="el-icon-full-screen"></i></span></template>
                            </el-autocomplete>
                            <el-table :data="dialogData.returnItems" border fit highlight-current-row size="small">
                                <el-table-column label="图片【分类-条形码】资产名称" min-width="160">
                                    <template slot-scope="scope">
                                        <el-image class="asset-image" v-if="scope.row.imageUrl"
                                            :src="uploadBaseUrl + scope.row.imageUrl" 
                                            :preview-src-list="[uploadBaseUrl + scope.row.imageUrl]">
                                            <div slot="error">
                                                <i class="el-icon-picture-outline error-image"></i>
                                            </div>
                                        </el-image>
                                        <span>{{ mxTranceAssetData(scope.row) }}</span>
                                        <span :class="mxTranceAssetData(scope.row) ? '' : 'mrgl10'">{{ scope.row.assetName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="serialNumbers" label="序列号" width="360">
                                    <template slot="header">
                                        序列号
                                        <el-button class="mrgl10" type="warning" size="mini" @click="openScanCode">批量扫码</el-button>
                                    </template>
                                    <template slot-scope="scope" v-if="scope.row.needSerialNumber">
                                        <!--手机扫码-->
                                        <span class="serial-scan-btn" @click="openSerialHtmlScan(scope.row, scope.$index)" v-if="!$root.isPc"><i class="el-icon-full-screen"></i></span>
                                        <el-select class="c-pw100" v-model="scope.row.serialNumbers" multiple filterable remote
                                            :multiple-limit="scope.row.maxCount"
                                            :remote-method="(v) => { loadOptions(v, scope.row, scope.$index) }" 
                                            @change="changeQuantity(scope.$index)"
                                            @remove-tag="(v, index) => { removeTag(v, scope.$index) }"
                                            @keyup.enter.native="(v) => { execSerial(v, scope.row, scope.$index) }"
                                            placeholder="扫码或直接输入序列号" size="small">
                                            <el-option v-for="snItem in scope.row.selectNumbers" :key="snItem.sn" :value="snItem.sn" :label="snItem.sn"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="count" label="数量" width="100">
                                    <template slot-scope="scope">
                                        <el-input type="number" v-model="scope.row.count" 
                                            :max="scope.row.maxCount" min="0" :disabled="scope.row.needSerialNumber" size="mini"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="60">
                                    <template slot-scope="scope">
                                        <div @click="_delete(scope.row, scope.$index)"><i class="el-icon-delete"></i></div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">确认完成</el-button>
            </span>
        </el-dialog>

        <!--扫码getScanSerials-->
        <scan-serial :ref="refToScanSerial" @scanSerials="setAssetDatas"></scan-serial>
        <!--调用摄像头扫码-->
        <html-scan :ref="refHtmlScan" @success="htmlScanSuccess(arguments)"></html-scan>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
import configMixins from "@/mixins/config"
import ScanSerial from "./scanSerial"
import HtmlScan from "@/components/HtmlScan"
import { getStockWarehourse } from "@/utils/storages"
import { Weixin } from '@/utils/wx-jssdk.js'
import * as funCommon from "@/api/common"
export default {
    name: "compExchangeComplete",
    mixins: [enumConfigs, configMixins],
    components: { ScanSerial, HtmlScan },
    data() {
        return {
            refToScanSerial: "refExchangeCompleteToScanSerial",
            refAutoComplete: "refExchangeCompleteToAutoComplete",
            refHtmlScan: "refExchangeCompleteStockToHtmlScan",
            showDialog: false,
            dialogData: {},
            formRefName: "refExchangeComplete",
            formRules: {
                returnItems: [{ required: true, validator: (rule, value, callback) => {
                    if (this.dialogData.returnItems && this.dialogData.returnItems.length > 0) {
                        callback()
                    } else {
                        callback(new Error("请添加换货清单"))
                    }
                }, trigger: 'change' }]
            },
            keyword: ""
        }
    },
    methods: {
        open(row) {
            this.dialogData = JSON.parse(JSON.stringify(row))
            this.dialogData.returnItems = []
            this.showDialog = true
        },
        clearAutoComplete() {
            document.activeElement.blur()
            this.keyword = ""
            this.$refs[this.refAutoComplete].getData("")
            this.$refs[this.refAutoComplete].focus()
        },
        querySearchAsync(queryString, cb) {
            // 查询商品
            var restaurants = []
            if (!queryString) {
                cb(restaurants)
                return false
            }
            var query = {
                categoryId: null,
                companyId: "",
                ownerType: this.valOwnerAssetUser(),
                ownerId: this.dialogData.assetUser && this.dialogData.assetUser.id || "",
                keywords: queryString,
                maxResultCount: 20
            }
            funStock.getStockSearch(query).then(res => {
                for (var i in res) {
                    var item = res[i]
                    var temp = {}
                    temp.id = item.id
                    temp.value = item.name
                    temp.barCode = item.barCode
                    temp.imageUrl = item.imageUrl || ""
                    temp.needSerialNumber = item.hasSerialNumber
                    temp.category = item.category
                    temp.maxCount = item.stock
                    restaurants.push(temp)
                }
                cb(restaurants)
            }).catch(e => {
                this.$alert('资产加载出错!')
                return false
            })
        },
        execSelect() {
            // 扫码自动识别
            let obj = {}
            let searchDatas = []
            if (this.keyword) {
                var query = {
                    categoryId: null,
                    companyId: "",
                    ownerType: this.valOwnerAssetUser(),
                    ownerId: this.dialogData.assetUser && this.dialogData.assetUser.id || "",
                    keywords: this.keyword,
                    maxResultCount: 20
                }
                funStock.getStockSearch(query).then(res => {
                    for (var i in res) {
                        var item = res[i]
                        var temp = {}
                        temp.id = item.id
                        temp.value = item.name
                        temp.barCode = item.barCode
                        temp.imageUrl = item.imageUrl || ""
                        temp.needSerialNumber = item.hasSerialNumber
                        temp.category = item.category
                        temp.maxCount = item.stock
                        searchDatas.push(temp)
                    }
                    if (searchDatas.length == 1) {
                        obj = searchDatas[0]
                        this.handleSelect(obj)
                    } else if (searchDatas.length > 1) {
                        this.warningMsg("资产选项不止一个，请手动选择!")
                        return false
                    } else {
                        this.errorMsg("不存在当前资产信息!")
                        return false
                    }
                }).catch(e => {
                    this.$alert('资产加载出错!')
                    return false
                })
            }
        },
        handleSelect(item) {
            this.clearAutoComplete()
            if (this.dialogData.warehouseId && item.maxCount === 0) {
                this.warningMsg(`${item.value}，库存不足!`)
                return false
            }
            // 重复资产不用添加
            let asset = this.dialogData.returnItems.find(x => {
                return x.assetId == item.id
            })
            if (asset) {
                this.warningMsg(`${asset.assetName}，已存在资产列表!`)
                return false
            }
            let temp = {}
            temp.assetId = item.id
            temp.assetName = item.value // item.value
            temp.barCode = item.barCode
            temp.needSerialNumber = item.needSerialNumber
            temp.serialNumbers = item.serialNumbers || []
            temp.lastSerialNumbers = item.serialNumbers || []
            temp.count = temp.needSerialNumber ? temp.serialNumbers.length : 1
            temp.imageUrl = item.imageUrl
            temp.category = item.category
            temp.maxCount = item.maxCount
            temp.selectNumbers = []
            this.dialogData.returnItems.push(temp)
        },
        changeQuantity(index) {
            // 创建条目改变数据，先强制不能删除，只能在移除中操作
            let item = this.dialogData.returnItems[index]
            if (item.serialNumbers.length < item.lastSerialNumbers.length) {
                item.serialNumbers = item.lastSerialNumbers
            }
            let serLen = item.serialNumbers.length
            // 序列号数量超出，则截取
            if (item.maxCount > 0 && serLen > item.maxCount) {
                item.serialNumbers = item.serialNumbers.slice(0, serLen - 1)
                this.warningMsg(item.assetName + "，最大可更换数量为：" + item.maxCount)
            }
            item.lastSerialNumbers = item.serialNumbers
            item.count = item.serialNumbers.length
        },
        loadOptions(val, row, index, isEnter) {
            if (!val) {
                return false
            }
            let params = {
                assetId: row.assetId || "",
                ownerType: this.valOwnerAssetUser(),
                ownerId: this.dialogData.assetUser && this.dialogData.assetUser.id || "",
                keywords: val,
                maxResultCount: 20
            }
            funStock.getStockSnSearch(params).then(res => {
                if (!isEnter) {
                    // 非按enter键，为搜索
                    this.dialogData.returnItems[index].selectNumbers = res
                } else {
                    if (res.length <= 0) {
                        // 为了强制更新输入框的值
                        this.dialogData.returnItems[index].serialNumbers = JSON.parse(JSON.stringify(this.dialogData.returnItems[index].serialNumbers))
                        this.changeQuantity(index)
                        this.errorMsg("序列号不存在!")
                        return false
                    }
                    // 按enter键为确定
                    if (this.dialogData.returnItems[index].serialNumbers.indexOf(res[0].sn) === -1) {
                        this.dialogData.returnItems[index].serialNumbers.push(res[0].sn)
                    } else {
                        // 为了强制更新输入框的值
                        this.dialogData.returnItems[index].serialNumbers = JSON.parse(JSON.stringify(this.dialogData.returnItems[index].serialNumbers))
                        this.warningMsg("序列号已存在!")
                    }
                    this.changeQuantity(index)
                }
            })
        },
        execSerial(e, row, index) {
            let val = e.target._value
            this.loadOptions(val, row, index, true)
        },
        removeTag(val, index) {
            // 手动点击x移除时触发
            let item = this.dialogData.returnItems[index]
            item.serialNumbers = item.serialNumbers.filter(x => {
                return x != val
            })
            item.lastSerialNumbers = item.serialNumbers
            item.count = item.serialNumbers.length
        },
        _delete(row, index) {
            this.confirm("确定要删除当前数据吗？").then(() => {
                this.dialogData.returnItems.splice(index, 1)
            })
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let commitData = {
                        billId: this.dialogData.id,
                        returnItems: this.dialogData.returnItems.map(x => {
                            let temp = { 
                                assetId: x.assetId, 
                                count: x.count, 
                                serialNumbers: x.serialNumbers 
                            }
                            return temp
                        }) || []
                    }
                    window.$common.fullLoading()
                    // 完成更换
                    funStock.completeExchangeStock(commitData).then((res) => {
                        this.commonSuccess("操作成功!", res)
                    })
                } else {
                    return false
                }
            })
        },
        openScanCode() {
            this.$refs[this.refToScanSerial].open({ ownerId: this.dialogData.assetUser && this.dialogData.assetUser.id })
        },
        setAssetDatas(datas) {
            // 扫码回传数据
            for (let i in datas) {
                let item = datas[i]
                // 判断当前数据是否在商品中存在
                let assetIndex = this.dialogData.returnItems.findIndex(x => {
                    return x.assetId == item.assetId
                })
                // 存在则先判断资产是否相同
                if (assetIndex != -1) {
                    let tempItem = JSON.parse(JSON.stringify(this.dialogData.returnItems[assetIndex]))
                    if (tempItem.serialNumbers.indexOf(item.serialNumber) == -1) {
                        tempItem.serialNumbers.push(item.serialNumber)
                    } else {
                        this.warningMsg("序列号已存在!")
                    }
                    tempItem.lastSerialNumbers = tempItem.serialNumbers
                    this.dialogData.returnItems.splice(assetIndex, 1, tempItem)
                    this.changeQuantity(assetIndex)
                } else {
                    item.serialNumbers = [item.serialNumber]
                    item.lastSerialNumbers = item.serialNumbers
                    this.dialogData.returnItems.push(item)
                    this.changeQuantity(0)
                }
            }
        },
        async getWechatSign(row) {
            await funCommon.GetWechatTicket().then(res => {
                let jssdkRes = res
				let wxJS = new Weixin()
                wxJS.init({
                    appId: jssdkRes.appId, // 必填，公众号的唯一标识
                    timestamp: jssdkRes.timestamp, // 必填，生成签名的时间戳
                    nonceStr: jssdkRes.nonceStr, // 必填，生成签名的随机串
                    signature: jssdkRes.signature // 必填，签名
                })
                wxJS.scanQRCode(wres => {
                    this.htmlScanSuccess([wres.resultStr, row])
                })
            })
        }, 
        // 打开条形码扫码
        openBarcodeHtmlScan() {
            let browser = window.$util.getBrowser()
            if (browser && browser.wechat) {
                this.getWechatSign()
            } else {
                this.$refs[this.refHtmlScan].open()
            }
        },
        // 打开序列号扫码
        openSerialHtmlScan(row, index) {
            row.rowIndex = index
            let browser = window.$util.getBrowser()
            if (browser && browser.wechat) {
                this.getWechatSign(row)
            } else {
                this.$refs[this.refHtmlScan].open(row)
            }
        },
        // 调用摄像头扫码成功
        htmlScanSuccess(arr) {
            if (!arr[1]) {
                // 没有第二个参数，则为扫条形码
                this.keyword = arr[0]
                this.execSelect()
            } else {
                // 有第二个参数，则为扫序列号
                this.loadOptions(arr[0], arr[1], arr[1].rowIndex, true)
            }
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val, data) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData(data)
            })
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>